<template>
    <div class="department-index">
        <navigationBar />

        <!-- 日期选择器 -->

        <el-form :inline="true">
            <!-- 级联选择器 -->
            <el-form-item label="部  门" prop="deptId">
                <div class="block">
                    <el-cascader
                        ref="cascader"
                        v-model="form.deptId"
                        :options="deptList"
                        :props="{ checkStrictly: true }"
                        style="width:80%"
                        size="mini"
                        @change="change"
                        placeholder="请输入部门"
                        clearable
                    ></el-cascader>
                </div>
            </el-form-item>
            <!-- 日期选择器 -->
            <el-form-item label="起止时间：">
                <div class="block">
                    <el-date-picker
                        v-model="dateValue"
                        type="daterange"
                        size="mini"
                        :picker-options="pickerOptions"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </div>
            </el-form-item>
            <!-- 多选框 -->
            <el-form-item label="报表类型：">
                <el-select v-model="formType" size="mini" placeholder="请选择报表类型">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="findWorkingTimeList()" size="mini">查询</el-button>
                <el-button type="primary" @click="exportTableToEcxelf()" size="mini">导出</el-button>
            </el-form-item>
        </el-form>

        <!-- 列表区 -->
        <el-table
            :data="work_list"
            border
            highlight-current-row
            :cell-style="{padding:'10px'}"
            v-loading="listLoading"
            style="width: 100%; "
        >
            <el-table-column prop="idNo" label="序号"></el-table-column>
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column prop="deal_avg_num" label="平均处理时长(小时)" v-if="formType == '1'"></el-table-column>
            <el-table-column prop="deal_order_num" label="处理工单数量(条)" v-if="formType == '1'"></el-table-column>
            <el-table-column prop="deal_working_hours" label="总时长(小时)" v-if="formType == '1'"></el-table-column>
            <el-table-column prop="deal_avg_num" label="平均处理时长(小时)" v-if="formType == '1'"></el-table-column>

            <el-table-column prop="send_avg_num" label="平均提单时长(小时)" v-if="formType == '2'"></el-table-column>
            <el-table-column prop="send_order_num" label="发单数量(条)" v-if="formType == '2'"></el-table-column>
            <el-table-column prop="send_working_hours" label="发单总时长(小时)" v-if="formType == '2'"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="Pagination" style="text-align: right;margin-top: 10px;">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="pageNo"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="count"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import navigationBar from "@/components/navigationBar";
import { getDeptInfoList } from "@/api/getUcenterData";
import { findWorkingTime } from "@/api/getBaseData";
export default {
    data() {
        return {
            count: 0,
            pageNo: 1,
            pageSize: 3,
            // 总页数
            page: 0,

            //页头框中的按钮组
            buttonList: [
                {
                    backgroundColor: "#52D1AF",
                    icon: "/static/img/add.png",
                    text: "添加项目",
                },
            ],

            // 工单工时列表
            work_list: [],

            all_work_list: [],

            // 搜索区表单
            form: {
                // 用来装部门id数组的
                deptId: "",
                // 用来装选中部门id的
                departId: "",
            },

            // 部门列表
            deptList: [],

            listLoading: false,

            // 报表类型
            formType: "0",

            dateValue: "",

            // 日期选择器的快捷键
            pickerOptions: {
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },

            options: [
                {
                    value: "0",
                    label: "请选择类型",
                },
                {
                    value: "1",
                    label: "接单工时汇总报表",
                },
                {
                    value: "2",
                    label: "提单工时汇总报表",
                },
            ],
        };
    },
    components: {
        navigationBar,
    },
    created() {},
    mounted() {
        // this.findWorkingTimeList();
        this.getDeptInfoListf();
        // this.exportTableToEcxelf();
    },

    methods: {
        findWorkingTimeList: function (size) {
            let para = {
                dept_id: this.form.departId,
                start_time: this.dateValue[0],
                end_time: this.dateValue[1],
                type: this.formType,
                pageNo: this.pageNo,
                pageSize: this.pageSize,
            };
            if (size != "" && size != null) {
                para.pageSize = size;
            }
            if (this.dateValue != "") {
                para = {
                    dept_id: this.form.departId,
                    start_time: this.dateValue[0],
                    end_time: this.dateValue[1],
                };
            }
            findWorkingTime(para).then((result) => {
                console.log("结果：", result);
                this.work_list = result.data.list;
                // this.pageNo = result.date.pageNum
                this.page = result.data.pages;
                this.count = result.data.total;
                console.log("总数：", this.count);
                for (var i = 0; i < this.work_list.length; i++) {
                    this.work_list[i].idNo =
                        (this.pageNo - 1) * this.pageSize + i + 1;
                }
            });

            // 第二次请求，测试用
            let para2 = {
                dept_id: this.form.departId,
                start_time: this.dateValue[0],
                end_time: this.dateValue[1],
                type: this.formType,
                pageNo: this.pageNo,
                pageSize: this.count,
            };
            findWorkingTime(para2).then((result) => {
                console.log("结果2：", result);
                this.all_work_list = result.data.list;
                // this.pageNo = result.date.pageNum
                this.page = result.data.pages;
                this.count = result.data.total;
                console.log("总数：", this.count);
                for (var i = 0; i < this.all_work_list.length; i++) {
                    this.all_work_list[i].idNo =
                        (this.pageNo - 1) * this.pageSize + i + 1;
                }
            });
        },

        // 导出表格
        exportTableToEcxelf: function () {
            // 第二次请求，测试用
            let para2 = {
                dept_id: this.form.departId,
                start_time: this.dateValue[0],
                end_time: this.dateValue[1],
                type: this.formType,
                pageNo: this.pageNo,
                pageSize: this.count,
            };
            findWorkingTime(para2).then((result) => {
                this.all_work_list = result.data.list;
                // this.pageNo = result.date.pageNum
                this.page = result.data.pages;
                this.count = result.data.total;
                for (var i = 0; i < this.all_work_list.length; i++) {
                    this.all_work_list[i].idNo =
                        (this.pageNo - 1) * this.pageSize + i + 1;
                }

                require.ensure([], () => {
                    const {
                        export_json_to_excel,
                    } = require("@/utils/excel/Export2Excel");
                    // const tHeader = ["序号", "IMSI", "MSISDN", "证件号码", "姓名"];
                    // const filterVal = ["ID", "imsi", "msisdn", "address", "name"];
                    // const list = [
                    //     { ID: 1, imsi: 2, msisdn: 3, address: 4, name: 5 },
                    // ];
                    // const tHeader = [];
                    // const filterVal = [];
                    // const list = [];

                    if (this.formType == "0") {
                        return;
                    } else if (this.formType == "1") {
                        const tHeader = [
                            "序号",
                            "姓名",
                            "平均处理时长（小时）",
                            "处理工单数量（条）",
                            "总时长（小时）",
                        ];
                        const filterVal = [
                            "idNo",
                            "name",
                            "deal_avg_num",
                            "deal_order_num",
                            "deal_working_hours",
                        ];
                        const list = this.all_work_list;
                        export_json_to_excel(
                            tHeader,
                            filterVal,
                            list,
                            "接单工时报表"
                        ); //标题
                    } else if (this.formType == "2") {
                        const tHeader = [
                            "序号",
                            "姓名",
                            "平均发单时长（小时）",
                            "发单数量（条）",
                            "发单总时长（小时）",
                        ];
                        const filterVal = [
                            "idNo",
                            "name",
                            "send_avg_num",
                            "send_order_num",
                            "send_working_hours",
                        ];
                        const list = this.work_list;
                        export_json_to_excel(
                            tHeader,
                            filterVal,
                            list,
                            "发单工时报表"
                        ); //标题
                    }
                });
            });
        },
        formatJsonMap: function (filterVal, jsonData) {
            return jsonData.map((v) => filterVal.map((j) => v[j]));
        },

        //部门列表信息
        getDeptInfoListf: function () {
            getDeptInfoList().then((resData) => {
                if (resData.resultCode == 1) {
                    let res = resData.data;
                    let farr = [];
                    let sarr = [];
                    let tarr = [];
                    if (res.length > 0) {
                        let resData = res;
                        for (var i = 0; i < resData.length; i++) {
                            if (!farr[resData[i].fId]) {
                                farr[resData[i].fId] = {
                                    label: resData[i].fDeptName,
                                    value: resData[i].fId,
                                };
                            }
                            if (!sarr[resData[i].sId]) {
                                sarr[resData[i].sId] = {
                                    label: resData[i].sDeptName,
                                    value: resData[i].sId,
                                    parentId: resData[i].sParentId,
                                };
                            }
                            if (!tarr[resData[i].tId]) {
                                tarr[resData[i].tId] = {
                                    label: resData[i].tDeptName,
                                    value: resData[i].tId,
                                    parentId: resData[i].tParentId,
                                };
                            }
                        }
                        for (var i = 0; i < tarr.length; i++) {
                            if (tarr[i]) {
                                if (!sarr[tarr[i].parentId].children) {
                                    sarr[tarr[i].parentId].children = [];
                                }
                                sarr[tarr[i].parentId].children.push(tarr[i]);
                            }
                        }
                        for (var i = 0; i < sarr.length; i++) {
                            if (sarr[i]) {
                                if (!farr[sarr[i].parentId].children) {
                                    farr[sarr[i].parentId].children = [];
                                }
                                farr[sarr[i].parentId].children.push(sarr[i]);
                            }
                        }
                        this.deptList = [];
                        for (var i = 0; i < farr.length; i++) {
                            if (farr[i]) {
                                this.deptList.push(farr[i]);
                            }
                        }
                    }
                } else {
                    this.$message({
                        message: resData.resultMsg,
                        type: "error",
                    });
                }
            });
        },

        // 级联选择器选中目标后执行的函数
        change: function (value) {
            this.form.departId = value[value.length - 1];
        },
        // 分页中的函数
        handleCurrentChange: function (pageNo) {
            this.pageNo = pageNo;
            this.findWorkingTimeList();
        },
    },
};
</script>
<style lang="scss">
.admin-index {
    .el-table thead {
        color: #21293b;
        font-size: 0.8rem;
    }
    .el-table th,
    .el-table thead {
        background-color: #e9ecee;
        height: 3rem;
    }
    .el-table {
        font-size: 0.8rem;
    }
}
</style>
